import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import { productPath } from 'utils'
import { Cards, CategoryCard } from '@accodeing/gatsby-theme-switsbake'

const productImage = (images, articleId) =>
  images.find((image) => image.name === articleId).childImageSharp
    .gatsbyImageData

const Index = ({ data }) => {
  const newProducts = data.allGoogleSheetSortimentRow.nodes.filter(
    (data) => data.utvaldprodukt === 'ja'
  )
  const firstHeading = data.allGoogleSheetRubrikerRow.nodes.find((heading) => {
    if (heading.rubrikid === 'stor-rubrik') return heading
    return null
  })

  const secondHeading = data.allGoogleSheetRubrikerRow.nodes.find((heading) => {
    if (heading.rubrikid === 'sub-rubrik') return heading
    return null
  })

  const featuredProducts = data.allGoogleSheetRubrikerRow.nodes.find(
    (heading) => {
      if (heading.rubrikid === 'utvalda-produkter') return heading
      return null
    }
  )
  const allProductImages = data.allProductImages.edges.map((edge) => edge.node)

  return (
    <Layout
      pageTitle='Hem'
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row row--with-auto-margin'>
        <section className='section section--with-background'>
          <h1 className='heading page__heading'>{firstHeading.text}</h1>
          <p className='section__text'>
            Välkommen till Hägges, Svensk fikatradition sedan 1956. Vi bakar
            muffins, mjuka kakor och kondisbitar med stor omsorg på råvaror av
            högsta kvalitet, med ägg från frigående höns. Njut av din fikastund
            med någon av våra klassiker eller upptäck nya favoriter i vårt
            sortiment.
          </p>
        </section>

        <section className='section section--with-background featured-products'>
          {/*<h2 className='section__heading'>{featuredProducts.text}</h2>*/}
          {newProducts.length > 0 ? (
            <Cards>
              {newProducts.map((product) => (
                <CategoryCard
                  title={product.artikelnamn}
                  key={product.artikelnamn}
                  linkTo={productPath(product.kategoriid, product.artikelid)}
                >
                  <GatsbyImage
                    image={productImage(allProductImages, product.artikelid)}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </CategoryCard>
              ))}
            </Cards>
          ) : null}
          <div className='mood-images'>
            <a
              href='https://www.matspar.se/varumarke/hagges'
              target='_blank'
              rel='noreferrer'
            >
              <GatsbyImage
                alt='Påse med "Lussemuffins med äkta saffran" som står på ett bord bredvid ett fat med upplagda muffins samt texten "Julens godaste fika kommer från Hägges".'
                image={data.moodImage.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: 'contain' }}
              />
            </a>
            <a
              href='https://www.matspar.se/varumarke/hagges'
              target='_blank'
              rel='noreferrer'
            >
              <GatsbyImage
                alt='En påse med saffranskaka och en med Pepparkaksmuffins som står på ett bord bredvid ett fat med upplagd saffranskaka. Framför påsarna ligger pepparkaksmuffins. Bredvid i bilden finns texten "Julens godaste fika kommer från Hägges".'
                image={data.moodImage2.childImageSharp.gatsbyImageData}
                imgStyle={{ objectFit: 'contain' }}
              />
            </a>
          </div>

          <p className='buy-button'>
            <a
              href='https://www.matspar.se/varumarke/hagges'
              target='_blank'
              rel='noreferrer'
              className='buy-button__link'
            >
              Köp våra produkter
            </a>
            <span className='buy-button__disclaimer'>
              I samarbete med Matspar
            </span>
          </p>
        </section>

        <section className='section section--with-background'>
          <h2 className='section__heading'>{secondHeading.text}</h2>
          <p className='section__text'>
            Dagligen ägnar sig våra ambitiösa och erfarna medarbetare åt att
            utveckla och baka de godaste kakorna av högkvalitativa råvaror som
            motsvarar våra konsumenters förväntningar. Alla våra produkter bakas
            med ägg från frigående höns. Förutom ägg och mjölk använder vi inga
            råvaror med animaliskt ursprung.
          </p>
          <p className='section__text'>
            I samma anda säljs och distribueras dessa produkter med hjälp av vår
            partner Polfärskt till Sveriges dagligvarubutiker. Genom detta
            fantastiska samarbete når vi dagligen över 1000 butiker.
          </p>
          <p className='section__text'>
            Vi är oerhört tacksamma för våra kunders, konsumenters samt
            medarbetares synpunkter vilket hjälper och stärker oss i
            vidareutvecklingen av vår verksamhet och våra produkter.
          </p>
        </section>

        <section className='section section--with-background'>
          <h2 className='section__heading'>Kvalitet och miljö</h2>
          <p className='section__text'>
            Switsbake som producerar Häggesprodukterna är certifierat enligt
            BRC, British Retail Consortium. En standard som är anpassad till de
            krav som ställs på livsmedelsindustri och detaljister för att
            säkerställa en hög matkvalitet. Standarden utvecklades av de största
            detaljhandelskedjorna i Storbritannien för att kunna utvärdera
            leverantörer av produkter till sina EMV (egna märkesvaror).
          </p>
          <p className='section__text'>
            BRC ställer krav på bl.a. anläggning, produkt- och processtyrning
            samt hygien för att kunna garantera bra mat. Ett krav är att
            företaget tillämpar HACCP, ett amerikanskt program för att minimera
            risken att tillverkningen går fel och maten blir olämplig att äta.
            Företaget gör ett antal riskanalyser, dokumenterar dessa, vidtar
            åtgärder och gör sedan regelbundna kontroller av resultatet.
          </p>
          <p className='section__text'>
            Varje år görs en revision av en auktoriserad BRC-revisor som
            kontrollerar företaget mot de krav som ingår i standarden.
          </p>
          <p className='section__text'>
            Den senaste revisionen på Switsbake utfördes i januari 2022.
            Switsbake erhöll då högsta betyg A.
          </p>
        </section>
        <section className='section section--with-background'>
          <h3 className='section__sub-heading'>Hägges miljöarbete</h3>
          <p className='section__text'>
            I vår verksamhet ska vi utnyttja naturens resurser så skonsamt som
            möjligt för att minimera miljöbelastningen och genom detta medverka
            till ett ekologiskt hållbart samhälle. Målet är att hushålla med
            råvaror och energi på bästa tänkbara sätt, utan att göra avkall på
            kvaliteten.
          </p>
          <span className='section__text'>
            Detta gör vi genom att:
            <ul>
              <li>
                Planera verksamheten och utifrån detta utarbeta mål och
                handlingsplaner som följs upp.
              </li>
              <li>Minska förbrukning av förpackningsmaterial</li>
              <li>
                Öka kunskapen hos alla anställda, skapa ansvar, väga in miljö,
                kvalitet och hälsa i sina arbetsuppgifter.
              </li>
              <li>
                Arbeta för ständiga förbättringar och förebyggande åtgärder inom
                miljö, kvalitet och arbetsmiljö.
              </li>
              <li>Följa lagar och avtal.</li>
            </ul>
          </span>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "cakes.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    moodImage: file(relativePath: { eq: "mood1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
      }
    }
    moodImage2: file(relativePath: { eq: "mood2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
      }
    }
    allProductImages: allFile(
      filter: { relativeDirectory: { eq: "products" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(width: 520, quality: 60, layout: CONSTRAINED)
          }
        }
      }
    }
    allGoogleSheetSortimentRow {
      nodes {
        kategoriid
        artikelnamn
        artikelid
        utvaldprodukt
      }
    }
    allGoogleSheetRubrikerRow {
      nodes {
        rubrikid
        text
      }
    }
  }
`

export default Index
